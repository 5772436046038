import React from "react";
import useFilter from "../../../lib/use-filter";

const removeFromArray = (arr, v) => arr.filter((a) => a !== v);

const MultiButtonFilter = ({ name, values, history }) => {
  const [filter, setFilter] = useFilter(name, []);

  console.log({ filter });
  return (
    <div className="flex ">
      {values.map((v) => (
        <button
          className={`${
            filter.includes(v?.value)
              ? "bg-maincolor text-white"
              : "bg-white  text-maincolor"
          } border-maincolor border-2 border-y-2 h-14 rounded-lg md:mx-4 mx-2 md:px-5 px-6  `}
          onClick={() =>
            setFilter(
              filter.includes(v?.value)
                ? removeFromArray(filter, v?.value)
                : [...filter, v?.value]
            )
          }
        >
          {v?.name}
        </button>
      ))}
    </div>
  );
};

export default MultiButtonFilter;
