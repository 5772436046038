import { BrowserRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";
import AuthLayout from "./layouts/Auth-Layouts";
import AppLayouts from "./layouts/App-Layouts";
import { useEffect } from "react";
import { useLanguage } from "./lib/Language-Context";
import Editpersonalinfolayouts from "./layouts/edit-personal-info-layouts";

const basePath = process.env.REACT_APP_BASE_PATH || "";

function App() {
  const [lang] = useLanguage();
  // eslint-disable-next-line
  useEffect(async () => {
    let isMounted = true;
    if (!isMounted) return;
    const directionTag = document
      .getElementsByTagName("html")[0]
      .getAttribute("dir");
    const style = document.getElementById("semanticStyle");
    if (directionTag === "ltr") {
      style.href = `/assets/css/semantic.min.css`;
    } else {
      style.href = `/assets/css/semantic.rtl.min.css`;
    }
    return () => {
      isMounted = false;
    };
  }, [lang]);

  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path={routes.auth.baseAuth} component={AuthLayout} />
          <Route path={routes.admin.baseAdmin} component={AppLayouts} />
          <Route
            path={routes.ediPersonalInfo.default}
            component={Editpersonalinfolayouts}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
