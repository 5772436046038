import React, { useState } from "react";
import patternEdit from "../../../src/assets/Pattern-edit.svg";
import logoEdit from "../../../src/assets/logo-admin-naqla.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikInput from "../../component/common/formik/formik-input";
import { Button } from "semantic-ui-react";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import { RiEye2Line, RiEyeCloseLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import routes from "../../routes";
import { useQuery } from "../../hooks/use-query";
import useLocalStorage from "../../lib/use-localstorage";
import useAxios from "../../lib/use-axios";
import axios from "axios";
import api from "../../api";
import { toast } from "react-toastify";

const Changepassword = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const query = useQuery();
  const tokenEdit = query.get("token");
  const [token, setToken] = useLocalStorage("tokenEdit", tokenEdit);

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const changePasswordSchema = Yup.object({
    password: Yup.string()
      .min(8, `${langContent?.enterPassmustbe8}`)
      .max(20, `${langContent?.enterPassmustbe20}`)
      .required(`${langContent?.enterPass}`),
    confarmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], `${langContent?.notMatch}`)
      .required(`${langContent?.reEnterPass}`),
  });
  const success = (content) => toast.success(content, {});

  const { run, isLoading, isError, error, data, status } = useAxios();
  const changepassword = (values) => {
    const body = {
      token: token,
      infoType: "password",
      infoData: values.password,
    };
    run(axios.post(api.criticalinfo.forgetPassword, body))
      .then((res) => {
        success(lang === "en" ? res?.data?.data?.en : res?.data?.data?.ar);
      })
      .catch((err) => {});
  };
  return (
    <div className="mx-auto max-w-7xl">
      <div className="relative">
        <img src={patternEdit} alt="patternEdit" />
        <div className="absolute sm:py-4 py-6 sm:px-12 px-20 bottom-0">
          <img className="py-4" src={logoEdit} alt="logo edit" />
          <p className="py-2 text-textGray1">{langContent?.editPersonal}</p>
        </div>
      </div>
      <div>
        <Formik
          initialValues={{
            password: "",
            confarmpassword: "",
          }}
          onSubmit={changepassword}
          validationSchema={changePasswordSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <div className="flex flex-wrap  gap-x-10 pt-8">
                <div className="w-96 mx-10">
                  <FormikInput
                    label={
                      <p className="text-textGray1">
                        {langContent?.newPassword}
                      </p>
                    }
                    placeholder={langContent?.newPassword}
                    name="password"
                    className={"gap-3 sm:w-[30rem] w-full mx-10"}
                    type={passwordType}
                    icon={
                      <div className="cursor-pointer " onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <RiEyeCloseLine className="md:text-2xl text-xl" />
                        ) : (
                          <RiEye2Line className="md:text-2xl text-xl" />
                        )}
                      </div>
                    }
                  />
                </div>
                <div className="w-96 mx-10">
                  <FormikInput
                    label={
                      <p className="text-textGray1">{langContent?.Renter}</p>
                    }
                    placeholder={langContent?.Renter}
                    name="confarmpassword"
                    className={"gap-3 sm:w-[30rem] w-auto mx-10"}
                    type={passwordType}
                    icon={
                      <div className="cursor-pointer " onClick={togglePassword}>
                        {passwordType === "password" ? (
                          <RiEyeCloseLine className="md:text-2xl text-xl" />
                        ) : (
                          <RiEye2Line className="md:text-2xl text-xl" />
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="flex md:justify-end justify-center px-0 md:px-32">
                <div className="flex gap-x-3 pt-40">
                  <Button
                    basic
                    color="yellow"
                    onClick={() => history.push(routes.auth.Login)}
                    className="w-48 h-14 bg-backgroundGray dark:bg-darkMood-backgroundBlack text-maincolor text-base font-medium rounded-xl font-serif "
                  >
                    {langContent?.login}
                  </Button>
                  <Button
                    loading={isLoading}
                    type="submit"
                    className="w-48 h-14 bg-maincolor text-white  text-base font-medium rounded-xl font-serif "
                  >
                    {langContent?.saveChanges}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Changepassword;
