import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import ForgetPassword from "../pages/auth/Forget-Password";
import LogIn from "../pages/auth/Log-in";
import routes from "../routes";

const AuthLayout = () => {
  return (
    <div>
      <Switch>
        <Route path={routes.auth.Login} component={LogIn} />
        <Route path={routes.auth.ForgetPass} component={ForgetPassword} />

        <Redirect to={routes.auth.Login} />
      </Switch>
    </div>
  );
};

export default AuthLayout;
