import React from "react";
import { Pagination } from "semantic-ui-react";
import useFilter from "../../../lib/use-filter";

const Paginationtable = ({ totalPages }) => {
  const [page, setPage] = useFilter("page", "1");
  const [perpage, setPerPage] = useFilter("perPage", "25");

  return (
    <Pagination
      className="mt-4 rounded-2xl   edit_for_Pagination "
      defaultActivePage={1}
      secondary
      ellipsisItem={null}
      firstItem={null}
      lastItem={null}
      totalPages={totalPages}
      onPageChange={(e, data) => setPage(data?.activePage)}
    />
  );
};

export default Paginationtable;
