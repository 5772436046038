import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { useLanguage } from "../../lib/Language-Context";

import useFilter from "../../lib/use-filter";
import { content } from "../../localization/content";
import DropdownFilter from "../common/formik/DropdownFilter";

const Dropdownlistorder = () => {
  const [lang, setLang] = useLanguage("");
  const options = [
    { key: 1, text: `${lang === "en" ? "Oldest" : " الأقدم"}`, value: "asc" },
    { key: 2, text: `${lang === "en" ? "Newest" : " الأحدث"}`, value: "desc" },
  ];

  return (
    <div>
      <DropdownFilter
        name="sort"
        options={options}
        selection
        defaultValue="asc"
      />
    </div>
  );
};

export default Dropdownlistorder;
