import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { MdOutlineReadMore } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import api from "../../../api";
import Avatar from "../../../component/common/Avatar/Avater";
import BasicinfoComp from "../../../component/Providers-Components/Basic-info-Comp";
import BranchTableOrdersDetails from "../../../component/Providers-Components/Branch-Table-Orders-Details";
import BranshTableProviderDetails from "../../../component/Providers-Components/Bransh-Table-Provider-Details";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/Language-Context";
import useAxios from "../../../lib/use-axios";
import { content } from "../../../localization/content";
import routes from "../../../routes";

const ProviderDetails = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { providerId } = useParams();
  const { run, isLoading, isError, error } = useAxios({});
  const [data, setData] = useState();

  React.useEffect(() => {
    run(
      authAxios
        .get(api.admin.providerDetails(providerId))
        .then(({ data }) => setData(data))
    );
  }, [run]);
  return (
    <div className="mx-auto max-w-7xl">
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        loading={isLoading}
      >
        {/* heder provider details  & back to provider */}
        <div className="flex justify-between py-8">
          <h1 className="text-4xl text-admingreen font-normal font-serif">
            {langContent.Provider_Details}
            {}
          </h1>
          <button
            onClick={() => {
              history.push(routes.admin.providerTabs);
            }}
            className="flex text-admingreen text-2xl font-normal "
          >
            <p className="font-serif">{langContent.Go_to_providers}</p>
            <BsArrowRight size={25} className="mt-1 mx-2 rtl:rotate-180  " />
          </button>
        </div>
        {/* provider name and avatar */}
        <div className="flex gap-9 mx-4">
          <Avatar
            styles={
              "w-24 h-24 rounded-full text-white  text-4xl border-4  flex justify-center items-center "
            }
            name={data?.data?.provider?.name}
          />
          <h1 className="text-maintext text-3xl font-normal flex items-center ">
            {data?.data?.provider?.name}
          </h1>
        </div>
        {/* provider basic info  and branshes */}
        <div className="grid md:grid-cols-3 sm:grid-cols-2  grid-cols-1 gap-10">
          <div className="my-7 ">
            <BasicinfoComp
              title={langContent.email}
              pragraph={data?.data?.provider?.email}
            />
            <BasicinfoComp
              title={langContent.phone_nember}
              pragraph={data?.data?.provider?.mainPhoneNumer}
            />
            <BasicinfoComp
              title={langContent.Commercial_number}
              pragraph={data?.data?.provider?.commercialNumber}
            />
            <BasicinfoComp
              title={langContent.license_number}
              pragraph={data?.data?.provider?.licenseNumber}
            />
          </div>
          <div className="col-span-2">
            <h1 className="text-admingreen text-3xl font-normal my-2 font-serif">
              {langContent.Branches}
            </h1>
            {/* <div className="bg-white rounded-lg shadow h-[21.5rem] overflow-y-scroll scrollbar-hide ">
              <BranshTableProviderDetails branchdate={data?.data?.branches} />
            </div> */}
            <div className="relative  ">
              <div className="bg-white rounded-lg shadow h-[43.5rem] overflow-y-scroll scrollbar-hide mt-6">
                <BranshTableProviderDetails branchdate={data?.data?.branches} />
                <div className="absolute   bottom-0 right-0  w-full  text-white  bg-white/60">
                  <button
                    className="w-fit rounded-2xl my-2 px-8 py-4 bg-maincolorLight text-maincolor text-xl flex justify-center  gap-2 mx-auto  "
                    onClick={() => {
                      history.push(routes.admin.ordertabs(providerId));
                      window.localStorage.setItem("providerId", providerId);
                    }}
                  >
                    <p>{langContent.shadow_more}</p>
                    <MdOutlineReadMore className="rtl:rotate-180" size={25} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default ProviderDetails;
