export const content = {
  en: {
    // log in page
    Log_in: "Log in",
    welcome: "welcome back!",
    User_Name: "User Name",
    Password: "Password",
    Forget_Password: "Forget Password ?",
    Notification: "Notification",
    profile: "Profile",
    log_out: "log out",
    // providers tab and table
    provider: "Providers",
    active_providers: "Active providers",
    pending_providers: "Pending providers",
    // table -- active
    name: "Name",
    email: "Email",
    Phone: "Phone",
    search: "Search...",
    phone_nember: "Phone number",
    view_details: "View details",
    Are_you_sure_you_want_to: "Are you sure you want to",
    reject_this_request: "reject this request ?",
    Reject: "Reject",
    // table --- pending
    commercail: "Commercail",
    license: "License",
    action: "Action",
    accept: "Accept",
    // Provider Details
    Provider_Details: "Provider Details",
    Go_to_providers: "Go to providers",
    Branches: "Branches",
    Commercial_number: "Commercial number",
    license_number: "license number",
    shadow_more: "show all orders",
    // orders
    Orders: "Orders",
    Go_to_provider_details: "Go to provider details",
    today: "Today",
    active: "Active",
    pending: "Pending",
    history: "History",
    pickup: "Pickup requests",
    Sort: "Sort",
    filter: "filter",
    date: "date",
    apply: "apply",
    cancel: "cancel",
    // header table
    Id: "Id",
    Quantity: "Quantity",
    Status: "Status",
    contanersize: "Contaner size",
    ReceivedDate: "Received Date",
    deliverydate: "Delivery date",
    Regular_pickup_date: "Regular pickup date",
    Address: "Address",
    Offer: "Offer",
    order_id: "Order Id",

    // order details
    OrderDetails: "Order Details",
    Productdetails: "Product details",
    Customer: "Customer",
    Shipped_to: "Shipped to:",
    Pickup: "Pickup",
    // payment
    Payment: "Payment",
    Paymentmethod: "Payment method",
    amount: "amount",
    // state
    Ontheway: "On the way",
    Arrived: "Arrived",
    Requested: "Requested",
    Waitingfortheuser: "Waiting for the user",
    Completed: "Completed",
    Canceled: "Canceled",
    Rejected: "Rejected",
    Earlypickuprequest: "Early pick up request",
    Regularpickupdate: "Regular pick up date",
    // profile
    Admin_personal_info: "Admin personal info",
    user_name: "User name",
    phone_number: "Phone number",
    Customer_support_number: "Customer support number",
    passwored: "passwored",
    Edit_account: "Edit account",
    Basic_info: "Basic info",
    Old_password: "Old password",
    New_password: "New password",
    Confirm_password: "Confirm password",
    Save: "Save",
    // error for formik
    required: "required",
    must_be_more_than_7_pass: "must be more than 7 characters",
    max_20_pass: "must be more than 20 characters",
    must_be_more_than_3_name: "must be more than 3 characters",
    max_20_name: "must be more than 20 characters",
    email_not_valid: "email not valid",
    not_match: "not match",
    //
    enterEmail: "Please enter your E-mail",
    continue: "Continue",
    //
    editPersonal: "Edit personal details",
    changeName: "Change name",
    changePassword: "Change password",
    changePhoneNumber: "Change phone number",
    login: "Login",
    saveChanges: " Save changes",
    confirmNewNumber: "Confirm new number",
    //
    Resend: "Resend Code",
    Submit: "Submit OTP",
    PleaseSet: "Please set your new password",
    newPassword: "New password",
    Renter: "Re-enter Password",
    Reset: "Reset your password",
    firstName: "First name",
    secoundName: "Secound name",
    enterFName: "Enter your first name",
    enterSName: "Enter your Secound name",
    enterPass: "Enter your password ",
    reEnterPass: "Re-enter your password ",
    notMatch: "Your password is not match",
    enterPhone: "Enter your phone number ",
    firstMustbe3: "The first name must be more than 3 letters ",
    firstMustbe20: "The first name must be less than 20 letters ",
    secoundMustbe3: "The secound name must be more than 3 letters ",
    secoundMustbe20: "The secound name must be less than 20 letters ",
    enterPassmustbe8: "Password must be 8 characters or more",
    enterPassmustbe20: "Password must be 20 characters or less",
    enterPhonemustbe11: "The phone number must be 11 numbers or more",
    enterPhonemustbe20: "The phone number must be 20 numbers or less",
    Adminname: "Enter your Admin name ",
    Adminnamemustne6: "The first name must be more than 6 letters",
    Adminnamemustne20: "The first name must be less than 20 letters",
    confirm: "Confirm",
  },

  ar: {
    // log in page
    Log_in: "تسجيل الدخول",
    welcome: "مرحبًا بك مرة أخرى!",
    User_Name: "اسم المستخدم",
    Password: " كلمة المرور",
    Forget_Password: "نسيت كلمة المرور؟",
    Notification: "الإشعارات",
    profile: "الملف الشخصي",
    log_out: "تسجيل خروج",
    // providers tab and table
    provider: "المزودون",
    active_providers: "المزودون النشطون",
    pending_providers: " المزودون المعلقون",
    // table -- active
    name: "الاسم",
    email: "البريد الإلكتروني",
    Phone: "الهاتف",
    search: "بجث...",
    phone_nember: "رقم الهاتف",
    view_details: "عرض التفاصيل",
    Are_you_sure_you_want_to: "هل أنت متأكد أنك تريد",
    reject_this_request: "رفض هذا الطلب؟",
    Reject: "رفض",
    // table --- pending
    commercail: "رقم تجاري",
    license: "الترخيص",
    accept: "قبول",
    action: "الأجراء",
    // Provider Details
    Provider_Details: "تفاصيل المزود",
    Go_to_providers: "انتقل إلى مزود الخدمة",
    Branches: "الفروع",
    Commercial_number: "الرقم تجاري",
    license_number: "رقم الترخيص",
    // orders
    Orders: "الطلبات",
    Go_to_provider_details: "انتقل إلى تفاصيل المزود",
    today: "اليوم",
    active: "نشط",
    pending: "المعلقة",
    history: "السجلات",
    pickup: "طلبات الاستلام",
    Sort: "رتب من حيث",
    filter: "تصفية",
    date: "التاريخ",
    apply: "تطبيق",
    cancel: "إلغاء",
    order_id: "رقم الطلب",

    // header table
    Id: "رقم الطلبات	",
    Quantity: "الكمية",
    Status: "الحالة",
    contanersize: "حجم الحاوية	",
    ReceivedDate: "تاريخ الطلب	 	",
    deliverydate: "تاريخ الإستلام ",
    Regular_pickup_date: "تاريخ التسليم  ",
    Address: "عنوان",
    Offer: "المبلغ",
    // order details
    OrderDetails: "تفاصيل الطلب",
    Productdetails: "تفاصيل المنتج",
    Customer: "اسم العميل",
    Shipped_to: "يم الشحن إلى",
    Pickup: " يلتقط",
    shadow_more: " إظهار المزيد من الطلبات",
    // payment
    Payment: "المدفوعات",
    Paymentmethod: "طريقة الدفع",
    amount: "المبلغ",
    // state
    Ontheway: "علي الطريق",
    Arrived: "وصل",
    Requested: "مطلوب",
    Waitingfortheuser: " في انتظار المستخدم",
    Completed: "مكتمل",
    Canceled: "ألغيت",
    Rejected: "مرفوض",
    Earlypickuprequest: "طلب الاستلام المبكر",
    Regularpickupdate: " تاريخ الاستلام العادي",
    // profile
    Admin_personal_info: "المعلومات الشخصية للمسؤول",
    user_name: "اسم المستخدم",
    phone_number: "رقم الهاتف",
    Customer_support_number: "رقم خدمة العملاء",
    passwored: "كلمة المرور",
    Edit_account: "تحرير الحساب",
    Basic_info: "المعلومات الأساسية",
    Old_password: "كلمة المرور القديمة",
    New_password: "كلمة مرور جديدة",
    Confirm_password: "تأكيد كلمة المرور",
    Save: "حفظ",
    // error for formik
    required: "مطلوب",
    must_be_more_than_7_pass: "يجب ان تكون  اكثر من ٧ ارقام",
    max_20_pass: "لا يجب ان تكون من ٢٠ رقم  ",
    must_be_more_than_3_name: "يجب ان تكون  اكثر من ٧ أحرف",
    max_20_name: "لا يجب ان تكون  اكثر من ٢٠ أحرف",
    email_not_valid: "تتاكد من الحساب المدخل بالشكل الصحيح",
    not_match: "غير متطابقة",
    //
    enterEmail: "برجاء كتابة البريد الاكتروني الخاص بك ",
    continue: "متابعة",

    //
    editPersonal: "تحرير التفاصيل الشخصية",
    changeName: "تغيير الاسم",
    changePassword: "تغيير كلمة المرور",
    changePhoneNumber: "تغيير رقم الهاتف",
    login: "تسجيل الدخول",
    saveChanges: " حفظ التغييرات",
    confirmNewNumber: "تأكيد الرقم الجديد",
    //
    Resend: "أعد إرسال الرمز",
    Submit: "إرسال OTP",
    PleaseSet: "الرجاء تعيين كلمة المرور الجديدة",
    newPassword: "كلمة مرور جديدة",
    Renter: "أعد إدخال كلمة المرور",
    Reset: "إعادة تعيين كلمة المرور الخاصة بك",
    firstName: "الاسم الأول",
    secoundName: "الاسم الثاني",
    enterFName: "اكتب اسمك الاول",
    enterSName: "اكتب اسمك الثاني",
    enterPass: "اكتب كلمة المرور",
    reEnterPass: "اعد كتابه كلمة المرور",
    notMatch: "كلمة المرور غير متطابقة",
    enterPhone: "اكتب رقم الهاتف الخاص بك",
    firstMustbe3: "يجب أن يكون الاسم الأول أكثر من ٣ أحرف ",
    firstMustbe20: "يجب أن يكون الاسم الأول اقل من ٢٠ أحرف ",
    secoundMustbe3: "يجب أن يكون الاسم الثاني أكثر من ٣ أحرف ",
    secoundMustbe20: "يجب أن يكون الاسم الثاني اقل من ٢٠ أحرف",
    enterPassmustbe8: "يجب أن تكون كلمة المرور  ٨ أحرف او اكثر",
    enterPassmustbe20: "يجب أن تكون كلمة المرور  ٢٠ أحرف او اقل",
    enterPhonemustbe11: "يجب أن يكون رقم الهاتف ١١ رقمًا أو أكثر",
    enterPhonemustbe20: "يجب أن يكون رقم الهاتف ٢٠ رقمًا أو أقل",
    Adminname: "أدخل اسم المسؤول",
    Adminnamemustne6: "يجب أن يكون الاسم الأول أكثر من ٦ أحرف",
    Adminnamemustne20: "يجب أن يكون الاسم الأول أقل من ٢٠ حرفًا",
    confirm: "تأكيد",
  },
};
