import React, { useState, useEffect } from "react";
import { FaPhoneAlt, FaShippingFast, FaTruckPickup } from "react-icons/fa";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Segment, Table } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/Language-Context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";
import routes from "../../routes";
import Avatar from "../common/Avatar/Avater";
import Status from "../common/status/status";

const OrderDetails = ({ backpath }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();
  const { orderId } = useParams();

  const [forceReload, setForceReload] = useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);
  const { run, isLoading, data, isError, error } = useAxios({});
  useEffect(() => {
    run(authAxios.get(api.admin.orderdetails(orderId))).then(() => {});
  }, [run, forceReload]);

  const transletContainerSiz = (size) => {
    if (size === "TINY") {
      return lang === "en" ? "Six yards" : " ستة ياردة";
    }
    if (size === "SMALL") {
      return lang === "en"
        ? "Ten or Twelve yards "
        : " عشرة او اثنتا عشر ياردة";
    }
    if (size === "MEDIUM") {
      return lang === "en" ? "Twenty yards" : " عشرون ياردة";
    }
    if (size === "LARGE") {
      return lang === "en" ? "Thrity yards" : " تلاثون ياردة";
    }
  };

  return (
    <div className="max-w-7xl mx-auto">
      <Segment
        className="border-none shadow-none bg-transparent p-0 m-0 -z-1"
        loading={isLoading}
      >
        <div className=" pt-10">
          <div className="text-4xl my-2 mt-16 ">{langContent.OrderDetails}</div>
          {/* <div className="text-subtext rtl:hidden mb-8">
          {pathname.split("/").join(" > ").slice(2)}
        </div> */}
        </div>
        {/* state order */}
        <div className=" flex justify-start ">
          <Status statusOrder={data?.status} />
        </div>
        {/* table and head  */}
        <div>
          <h1 className="text-subtext my-4 ">{langContent.Productdetails}</h1>
          <div className="border-t-2 border-border my-4"></div>
          <Table className="" basic="very">
            <Table.Header className="hidden md:contents">
              <Table.Row>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.Id}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.ReceivedDate}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.deliverydate}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.contanersize}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.Quantity}
                </Table.HeaderCell>
                <Table.HeaderCell className=" text-center border-none text-maintext font-medium ">
                  {langContent.amount}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <div className="my-2"></div>
              <Table.Row className="bg-white  shadow-md rounded-xl">
                <Table.Cell className="text-center  py-8 ltr:border-l-2 rtl:border-r-2 ltr:rounded-l-xl rtl:rounded-r-xl border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Id}
                    </h1>
                    <p className="py-4 md:py-0">{data?.id}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.ReceivedDate}
                    </h1>
                    <p className="py-4 md:py-0">
                      {new Date(data?.createdAt).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.deliverydate}
                    </h1>
                    <p className="py-4 md:py-0">
                      {new Date(data?.deliveryDate).toLocaleDateString("en-GB")}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.contanersize}
                    </h1>
                    <p className="py-4 md:py-0">
                      {transletContainerSiz(data?.container?.size)}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Quantity}
                    </h1>
                    <p className="py-4 md:py-0"> {data?.numberOfContainers}</p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 ltr:border-r-2 rtl:border-l-2 ltr:rounded-r-xl rtl:rounded-l-xl  border-y-2 border-border">
                  <div className="flex justify-between md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.amount}
                    </h1>
                    <p className="py-4 md:py-0 flex justify-center gap-x-2">
                      {data?.requests?.[0]?.offer?.payment
                        ?.supplierInvoiceShare || "---"}
                      <p className="text-maincolor">
                        {lang === "en" ? "SAR" : "ريال"}
                      </p>
                    </p>
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        {/* three card  */}
        <div className="grid md:grid-cols-3 grid-cols-1 mx-auto md:gap-20 gap-5">
          {/* first - card */}
          <div className="shadow-md border-2 border-border rounded-xl  mt-6">
            <h1 className="text-subtext p-2">{langContent.Payment}</h1>
            <div className="bg-white rounded-xl py-8">
              <p className="grid grid-cols-2 gap-10 mx-8 py-4">
                <h1 className="text-maintext">{langContent.Paymentmethod}</h1>
                <h1 className="text-subtext">
                  {data?.requests[0]?.offer?.payment?.paymentMethod || "---"}
                </h1>
              </p>
              <p className="grid grid-cols-2 gap-10 mx-8 py-4">
                <h1 className="text-maintext">{langContent.amount}:</h1>
                <h1 className="text-subtext">
                  {" "}
                  {data?.requests?.[0]?.offer?.payment?.supplierInvoiceShare ||
                    "---"}
                </h1>
              </p>
              <p className="grid grid-cols-2 gap-10 mx-8 py-4">
                <h1 className="text-maintext"> {langContent.Status}:</h1>
                <h1 className="text-maincolor">
                  {data?.requests[0]?.offer?.payment?.status || "---"}
                </h1>
              </p>
            </div>
          </div>
          {/* secound - card */}
          <div className="flex flex-col justify-center mt-6 shadow-md border-border border-2 rounded-xl">
            <h1 className="text-subtext p-2 ">{langContent.Customer}</h1>
            <div className="bg-white rounded-xl py-5  flex flex-col ">
              <p className="mx-auto">
                <Avatar
                  styles={
                    "text-3xl w-16 h-16 text-center rounded-full text-white py-4"
                  }
                  name={data?.user?.name}
                />
              </p>
              <h1 className="mx-auto py-2">{data?.user?.name}</h1>
              <p className="mx-auto py-2">{data?.user?.email}</p>
              <p className="flex mx-auto py-2">
                <FaPhoneAlt size={18} className="text-maincolor mt-1" />
                <p className="border-b-2 border-maincolor mx-2 ">
                  {data?.user?.phoneNumber}
                </p>
              </p>
            </div>
          </div>
          {/* therid - card */}
          <div className="mt-6 shadow-md rounded-xl border-border border-2 ">
            <h1 className="text-subtext p-2 ">{langContent.Address}</h1>
            <div className="bg-white rounded-xl ">
              <h1 className="flex ">
                <FaShippingFast className="text-maincolor mx-2" />
                <p className="text-maintext  ">{langContent.Shipped_to}:</p>
              </h1>
              <p className="text-subtext  text-sm text-center py-2">
                {data?.address?.area?.name},{data?.address?.streetName},
                {data?.address?.landmark}
              </p>
              <img
                className="w-48 h-32 object-cover my-2 rounded-3xl mx-auto "
                src={data?.address?.image?.link}
                alt="pic"
              />
            </div>
          </div>
        </div>

        {/* footter details button */}
        <div className="flex  justify-between  md:flex-row flex-col ">
          {/* rghit Pickup */}
          <div className="my-6 ">
            <h1 className="text-subtext py-2">{langContent.Pickup}</h1>
            <div className="bg-white rounded-2xl grid grid-cols-2  py-6  border-border border-2">
              <h2 className="flex mx-2">
                <FaTruckPickup
                  size={18}
                  className="text-maincolor mx-1.5 mt-1 "
                />
                <p className="text-subtext text-sm pt-1">
                  {langContent.Regular_pickup_date}:
                </p>
              </h2>
              <p className="text-maincolor text-lg mx-auto">
                {new Date(data?.expairyDate).toLocaleDateString("en-GB")}
              </p>
            </div>
          </div>
          {/* left button */}
          <div className=" md:mx-4 mx-auto my-8 md:flex md:justify-between grid md:grid-cols-2 grid-cols-1 gap-0 md:gap-5">
            <button
              onClick={() => {
                history.push(backpath);
              }}
              className=" h-14 w-56 py-2  my-10 rounded-full text-maincolor  border-maincolor border-2 "
            >
              {lang === "en" ? "close" : " اغلاق"}
            </button>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default OrderDetails;
