import React from "react";

const BasicinfoComp = ({ title, pragraph }) => {
  return (
    <div className="bg-white rounded-lg shadow w-full h-auto my-6 px-12  ">
      <h1 className="text-maintext py-8  text-xl font-serif ">{title}</h1>
      <p className="text-[#8B8585] pb-9 text-2xl overflow-clip">{pragraph}</p>
    </div>
  );
};

export default BasicinfoComp;
