import React from "react";
import { Dropdown, Input } from "semantic-ui-react";
import { IoMdArrowDropdown } from "react-icons/io";
import { AiFillCaretDown } from "react-icons/ai";
import useFilter from "../../lib/use-filter";
import { useDebouncedCallback } from "use-debounce";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";

const ThreeSearchInputOrders = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const options = [
    {
      key: "orderId",
      text: `${langContent.order_id}`,
      value: "orderId",
    },
    { key: "name", text: `${langContent.name}`, value: "name" },
    { key: "phoneNumber", text: `${langContent.Phone}`, value: "phoneNumber" },
  ];

  const [, setKey] = useFilter("searchKey", "orderId");
  const [, setval] = useFilter("searchValue", "");

  const debounced = useDebouncedCallback((value) => setval(value), 750);

  return (
    <div>
      <Input
        className="outline-none focus:outline-hidden rounded-xl  "
        label={
          <Dropdown
            className="bg-transparent border-[1px] border-border w-36 px-6 text-subtext/70  flex "
            basic
            options={options}
            defaultValue="orderId"
            icon={<AiFillCaretDown size={15} className="text-admingreen " />}
            onChange={(e, { value }) => {
              setKey(value);
            }}
          />
        }
        icon="search"
        labelPosition="left"
        placeholder={`${langContent.search}`}
        iconPosition="Right"
        onChange={(e, { value }) => {
          debounced(value);
        }}
      />
    </div>
  );
};

export default ThreeSearchInputOrders;
