import React from "react";
import { Message, Modal } from "semantic-ui-react";
import { BiError } from "react-icons/bi";

function AccpetProvidersModel({ massg, open, onClose }) {
  React.useEffect(() => {}, [massg]);

  return (
    <Modal
      className="w-[30rem] h-[20rem] bg-white rounded-3xl"
      open={open}
      onClose={onClose}
      closeIcon
    >
      {/* edit heeeeeeeeeeeeeeeeeeeeeeeeer */}
      <div className="p-4">
        <p className="flex justify-center pt-4 text-red-300">
          <BiError size={80} />
        </p>
        <Message
          error
          content={massg}
          className="text-xl text-center"
        ></Message>
      </div>
    </Modal>
  );
}

export default AccpetProvidersModel;
