import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import { FaUserCog } from "react-icons/fa";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import { Dropdown, Icon } from "semantic-ui-react";
import { TiUserOutline } from "react-icons/ti";
import { MdNotificationsActive } from "react-icons/md";
import MainLogo from "../../../../src/assets/White-naqla-logo.svg";
import { useAuthState } from "../../../context/auth-context";

const Sidebarmob = ({ handelSid }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const { pathname } = useLocation();
  const { logout } = useAuthState();

  return (
    <div className=" lg:flex   ">
      <div className=" flex flex-col justify-between h-screen z-[10000] bg-maincolor w-64 text-white fixed    ">
        <div className="  text-2xl  ">
          <div className=" pt-10 pb-2 px-2 border-b-2 border-selectedcolor/30 flex justify-start  ">
            <img className=" w-32  mx-2 " src={MainLogo} alt="MainLogo" />
          </div>

          <ul className=" ">
            <li
              onClick={handelSid}
              className={`    ${
                pathname.startsWith(routes.admin.providerTabs)
                  ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white  "
                  : ""
              }  mt-16`}
            >
              <Link
                to={routes.admin.providerTabs}
                className=" flex py-4  text-xl font-bold "
              >
                <FaUserCog className=" mx-4 text-2xl " />
                <p>{langContent.provider}</p>
              </Link>
            </li>

            <li
              onClick={handelSid}
              className={`    ${
                pathname.startsWith(routes.admin.Notification)
                  ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white  "
                  : ""
              }  mt-6`}
            >
              <Link
                to={routes.admin.Notification}
                className=" flex py-4  text-xl font-bold "
              >
                <MdNotificationsActive className=" mx-4 text-2xl " />
                <p>{langContent.Notification}</p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="">
          <div className="border-b-2 border-selectedcolor/30 ">
            <Dropdown
              trigger={
                <div className=" flex  px-2 text-xl  ">
                  <Icon name="world" />
                  <span className="capitalize  px-1 ">
                    {lang === "en" ? "Language" : "اللغة"}
                  </span>
                </div>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setLang("en");
                    window.location.reload();
                  }}
                >
                  {lang === "en" ? "English" : "الانجليزية"}
                </Dropdown.Item>
                <hr />
                <Dropdown.Item
                  onClick={() => {
                    setLang("ar");
                    window.location.reload();
                  }}
                >
                  {lang === "en" ? "Arabic" : "عربية"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div
            onClick={handelSid}
            className={`    ${
              pathname.startsWith(routes.admin.profile) ? "text-[#B18200]" : ""
            } mt-4`}
          >
            <Link to={routes.admin.profile}>
              <div className=" py-2 flex  cursor-pointer ">
                <TiUserOutline size={25} />
                <p className="mt-1 mx-2  ">{langContent.profile}</p>
              </div>
            </Link>
          </div>
          <div>
            <Link to={routes.auth.Login}>
              <button
                onClick={() => {
                  logout();
                }}
                className=" py-2 flex  cursor-pointer "
              >
                <BiLogOut size={25} />
                <p className="mt-1 mx-2  mb-10">{langContent.log_out}</p>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebarmob;
