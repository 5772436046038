import React from "react";
import Avatar from "../../../component/common/Avatar/Avater";
import * as Yup from "yup";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import { Form, Formik } from "formik";
import FormikInput from "../../../component/common/formik/formik-input";
import { FaLock, FaPhoneAlt, FaUserAlt } from "react-icons/fa";
import { Button, Segment } from "semantic-ui-react";
import { TiUserOutline } from "react-icons/ti";
import { IoIosMail } from "react-icons/io";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import { BiLogInCircle } from "react-icons/bi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Editprofile = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const { run, isLoading, data, isError, error } = useAxios({});
  React.useEffect(() => {
    run(authAxios.get(api.admin.profile)).then((res) => {});
  }, [run]);

  const {
    run: runPost,
    isLoading: isLoadingpost,
    data: dataPost,
    error: errorPost,
  } = useAxios({});
  const updatProfile = (value) => {
    runPost(authAxios.post(api.admin.profile, value))
      .then(() => {
        success(
          lang === "en"
            ? "basic info added successfully"
            : " تمت إضافة المعلومات الأساسية بنجاح"
        );
      })
      .catch((errr) => {
        err(lang === "en" ? errr.message.en : errr.message.ar);
      });
  };

  const basicInfoSchema = Yup.object({
    name: Yup.string()
      .min(3, `${langContent.must_be_more_than_3_name} `)
      .max(20, `${langContent.max_20_name}`),
    // .required(`${langContent.required}`),
    email: Yup.string()
      .email(`${langContent.email_not_valid}`)
      // .required(`${langContent.required}`)
      .trim(),
    phoneNumber: Yup.string()
      .min(8, `${langContent.must_be_more_than_7_pass}`)
      .max(20, `${langContent.max_20_pass}`),
    // .required(`${langContent.required}`),
    // .required(`${"req"}`),
    customerSupportNumber: Yup.string()
      .min(8, `${langContent.must_be_more_than_7_pass}`)
      .max(20, `${langContent.max_20_pass}`),
    // .required(`${langContent.required}`),
  });

  // const PasswordSchema = Yup.object({
  //   password: Yup.string()
  //     .min(8, `${langContent.SignUpScreen.password_must_be_8}`)
  //     .max(20, `${langContent.SignUpScreen.password_must_be_20}`)
  //     .required(`${langContent.SignUpScreen.required}`),
  //   confirmPassword: Yup.string().oneOf(
  //     [Yup.ref("password"), null],
  //     `${langContent.SignUpScreen.not_match}`
  //   ),
  // });

  const success = (content) =>
    toast.success(content, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const err = (content) =>
    toast.error(content, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return (
    <div className="max-w-7xl mx-auto">
      <Segment
        className="border-none shadow-none bg-transparent  -z-1 p-0 m-0"
        loading={isLoading}
      >
        <ToastContainer />
        <div>
          <div className="flex flex-col justify-center items-center py-4">
            <Avatar
              name={data?.name}
              styles={
                "w-32 h-32 text-6xl text-white border-8 rounded-full flex justify-center items-center"
              }
            />
            <div className="text-2xl py-4">{data?.name}</div>
          </div>
        </div>
        {/* edit graid section */}
        <div className="grid md:grid-cols-2 grid-cols-1  gap-x-16">
          <div>
            <h1 className="text-admingreen text-xl font-serif">
              {langContent.Basic_info}
            </h1>
            <div className="shadow-[#03A65299]/50 rounded-xl shadow">
              <Formik
                initialValues={{
                  name: data?.name || "",
                  email: data?.email || "",
                  phoneNumber: data?.phoneNumber || "",
                  customerSupportNumber: data?.customerSupportNumber || "",
                }}
                onSubmit={updatProfile}
                validationSchema={basicInfoSchema}
                enableReinitialize
              >
                {(formik) => (
                  <div className="mx-8 my-4">
                    <Form onSubmit={formik.handleSubmit}>
                      <FormikInput
                        className={"border-[#03A65299] border-[1px] rounded-lg"}
                        label={langContent.user_name}
                        placeholder={langContent.user_name}
                        name="name"
                        icon={
                          <TiUserOutline className=" text-xl text-[#03A65299]" />
                        }
                      />
                      <FormikInput
                        className={"border-[#03A65299] border-[1px] rounded-lg"}
                        label={langContent.email}
                        placeholder={langContent.email}
                        name="email"
                        icon={
                          <IoIosMail className=" text-xl text-[#03A65299]" />
                        }
                      />
                      <FormikInput
                        className={"border-[#03A65299] border-[1px] rounded-lg"}
                        label={langContent.phone_number}
                        placeholder={langContent.phone_number}
                        name="phoneNumber"
                        icon={
                          <FaPhoneAlt className=" text-xl text-[#03A65299]" />
                        }
                      />
                      <FormikInput
                        className={"border-[#03A65299] border-[1px] rounded-lg"}
                        label={langContent.Customer_support_number}
                        placeholder={langContent.Customer_support_number}
                        name="customerSupportNumber"
                        icon={
                          <FaPhoneAlt className=" text-xl text-[#03A65299]" />
                        }
                      />
                      <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                        {lang === "en" ? error?.message.en : error?.message.er}
                      </span>
                      <div className="flex justify-end">
                        <Button
                          loading={isLoadingpost}
                          className="my-4 mx-2 rounded-full text-white bg-admingreen flex gap-x-2  font-serif"
                        >
                          {langContent.Save}
                          <BiLogInCircle size={20} />
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
          {/* graid 2 */}
          <div>
            <h1 className="text-admingreen text-xl font-serif">
              {langContent.passwored}
            </h1>
            <div className="shadow-[#03A65299]/50  rounded-lg shadow">
              <Formik
                initialValues={{
                  Oldpass: "",
                  Newpass: "",
                  Confirmpass: "",
                }}
                // onSubmit={Login}
                // validationSchema={registerSchema}
              >
                {(formik) => (
                  <div className="mx-8 my-4">
                    <Form onSubmit={formik.handleSubmit}>
                      <div className="py-12">
                        <FormikInput
                          className={
                            "border-[#03A65299] border-[1px] rounded-lg"
                          }
                          label={langContent.Old_password}
                          placeholder={langContent.Old_password}
                          name="Old_password"
                          icon={
                            <FaLock className=" text-xl text-[#03A65299]" />
                          }
                        />
                        <FormikInput
                          className={
                            "border-[#03A65299] border-[1px] rounded-lg"
                          }
                          label={langContent.New_password}
                          placeholder={langContent.New_password}
                          name="New_password"
                          icon={
                            <FaLock className=" text-xl text-[#03A65299]" />
                          }
                        />
                        <FormikInput
                          className={
                            "border-[#03A65299] border-[1px] rounded-lg "
                          }
                          label={langContent.Confirm_password}
                          placeholder={langContent.Confirm_password}
                          name="Confirm_password"
                          icon={
                            <FaLock className=" text-xl text-[#03A65299]" />
                          }
                        />
                      </div>
                      {/* <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                    {lang === "en" ? error?.message : error?.message}
                  </span> */}
                      <div className="flex justify-end">
                        <Button
                          // loading={"isLoading"}
                          className="my-6  rounded-full text-white bg-admingreen flex gap-x-2  font-serif"
                        >
                          {langContent.Save}
                          <BiLogInCircle size={20} />
                        </Button>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Segment>
    </div>
  );
};

export default Editprofile;
