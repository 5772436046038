import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import loginpic from "../../../src/assets/pic-login.svg";
import logoadmin from "../../../src/assets/logo-admin-naqla.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import routes from "../../routes";
import { Button, Image } from "semantic-ui-react";
import FormikInput from "../../component/common/formik/formik-input";
import { FaUserAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import useAxios from "../../lib/use-axios";
import axios from "axios";
import api from "../../api";
import auth from "../../utils/auth";
import useLocalStorage from "../../lib/use-localstorage";
import Timer from "../../component/common/timer";
import Requestaneditmodel from "../../component/common/request-an-edit-model";

const ForgetPassword = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();

  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [sucssmsg, setSucssmsg] = useState();
  const { run, isLoading, data, isError, error } = useAxios({});

  const [canSendResendRequest, setCanSendResendRequest] = useLocalStorage(
    "canSendResendRequest",
    true,
    undefined,
    (val) => JSON.parse(val)
  );

  const sentEmail = (values) => {
    const body = {
      email: values?.email,
    };
    run(axios.post(api.auth.sendforgetpassword, body))
      .then((res) => {
        setCanSendResendRequest(false);
        setOpenAcceptModal(true);
        setSucssmsg(res?.data?.data?.message);
      })
      .catch((err) => {});
  };

  const registerSchema = Yup.object({
    email: Yup.string()
      .email(`${langContent.email_not_valid}`)
      .required(`${langContent.required}`)
      .trim(),
  });

  return (
    <div className="">
      <div className="relative">
        {/* section for background */}
        <div className="flex  h-screen">
          <div className="flex-1 ">
            <img className="md:m-14 m-2 " src={logoadmin} alt="" />
          </div>
          <div className="flex-1  login-right-img "></div>
        </div>
        {/* section for login  */}
        <div className="bg-white absolute z-40 rounded-3xl p-4 shadow-maincolor w-full md:w-[50rem]  shadow-md center-abs  ">
          <div>
            <div className="flex flex-col mt-6">
              <h1 className="text-center text-maintext md:text-2xl text-xl font-serif">
                {langContent?.enterEmail}
              </h1>
            </div>
            <div className="flex justify-center mx-4">
              <Formik
                initialValues={{
                  email: "",
                }}
                onSubmit={sentEmail}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      placeholder={langContent?.email}
                      label={langContent?.email}
                      name="email"
                    />

                    <span className="h-4 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-center">
                      {canSendResendRequest ? (
                        <Button
                          loading={isLoading}
                          className=" md:w-[25rem] w-[20rem] md:py-6 py-4 rounded-full text-white bg-maincolor font-serif my-4 "
                        >
                          {langContent?.continue}
                        </Button>
                      ) : (
                        <div className="my-6">
                          <Timer
                            className={
                              " text-white bg-maincolor px-6 py-1 rounded-xl  "
                            }
                            canSendResendRequest={canSendResendRequest}
                            setCanSendResendRequest={setCanSendResendRequest}
                          />
                        </div>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {openAcceptModal && (
        <Requestaneditmodel
          open={openAcceptModal}
          onClose={() => setOpenAcceptModal(false)}
          massg={lang === "en" ? sucssmsg?.en : sucssmsg?.ar}
        />
      )}
    </div>
  );
};

export default ForgetPassword;
