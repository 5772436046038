import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../../routes";
import useTab from "../../../hooks/use-tab";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import { useParams } from "react-router-dom";
import TodayOrdersTable from "../../../component/Orders-Components/Today-Orders-Table";
import PendingOrdersTable from "../../../component/Orders-Components/Pending-Orders-Table";
import ActiveOrdersTable from "../../../component/Orders-Components/Active-Orders-Table";
import HistoryOrdersTable from "../../../component/Orders-Components/History-Orders-Table";
import PickupRequestsTable from "../../../component/Orders-Components/Pickup-Order-Table";
import { BsArrowRight } from "react-icons/bs";

const OrderTabs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname } = useLocation();
  const { providerId } = useParams();
  const history = useHistory();

  const panes = [
    {
      menuItem: `${langContent.today}`,
      route: routes.admin.todayorders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full  rounded-2xl">
            <TodayOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.active}`,
      route: routes.admin.activeorders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <ActiveOrdersTable id={providerId} />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.pending}`,
      route: routes.admin.pendingorders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <PendingOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.history}`,
      route: routes.admin.historyorders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl">
            <HistoryOrdersTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.pickup}`,
      route: routes.admin.pickuporders,
      render: () => (
        <div>
          {/* <div className="text-subtext rtl:hidden mb-10">
            {pathname.split("/").join(" > ").slice(2)}
          </div> */}
          <Tab.Pane className="border-none w-full h-full rounded-2xl ">
            <PickupRequestsTable />
          </Tab.Pane>
        </div>
      ),
    },
  ];
  const { activeIndex, onTabChange } = useTab({ panes });
  var Id = localStorage.getItem("providerId");

  return (
    <div className="">
      {/* heder provider details  & back to provider */}
      <div className="flex justify-between py-8 max-w-7xl mx-auto">
        <h1 className="text-4xl text-admingreen font-normal font-serif">
          {langContent.Orders}
        </h1>
        <button
          onClick={() => {
            history.push(routes.admin.providerdetails(Id));
          }}
          className="flex text-admingreen text-2xl font-normal "
        >
          <p className="">{langContent.Go_to_provider_details}</p>
          <BsArrowRight size={25} className="mt-1 mx-2 rtl:rotate-180   " />
        </button>
      </div>
      <div className="mx-auto h-auto rounded-3xl max-w-7xl  px-4   mt-14  edit-For-order-tab ">
        <Tab
          menu={{
            secondary: true,
            className: "flex flex-wrap",
          }}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default OrderTabs;
