import React, { useState } from "react";
import { TiUserOutline } from "react-icons/ti";
import { FaLock, FaPhoneAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { IoIosMail } from "react-icons/io";
import api from "../../../api";
import Avatar from "../../../component/common/Avatar/Avater";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import useAxios from "../../../lib/use-axios";
import { authAxios } from "../../../config/axios-config";
import Editprofile from "./Edit-profile";
import { Segment } from "semantic-ui-react";

const ViewProfile = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];

  const [step, setStep] = useState(1);

  const handelStep = () => {
    setStep(2);
  };

  const { run, isLoading, data, isError, error } = useAxios({});
  React.useEffect(() => {
    run(authAxios.get(api.admin.profile)).then((res) => {});
  }, [run]);

  return (
    <div className="max-w-5xl mx-auto ">
      <Segment
        className="border-none shadow-none bg-transparent  -z-1 p-0 m-0"
        loading={isLoading}
      >
        <div className="text-4xl  text-admingreen  my-10 mx-4 md:mx-0">
          {langContent.Admin_personal_info}
        </div>
        {step === 1 && (
          <div>
            <div className="grid md:grid-cols-2 grid-cols-1 mx-4 md:mx-0 grid-row-3  gap-x-28 gap-y-14">
              <div className="flex col-span-2">
                <div className="flex items-center">
                  <Avatar
                    name={data?.name}
                    styles={
                      "w-32 h-32 text-6xl text-white border-8 rounded-full flex justify-center items-center"
                    }
                  />
                </div>
                <h1 className="flex items-center px-4 text-3xl ">
                  {data?.name}
                </h1>
              </div>
              {/* <div className="py-10 cursor-not-allowed">
                <h1 className="text-xl text-subtext">
                  {langContent.user_name}
                </h1>
                <div className="flex py-6">
                  <TiUserOutline size={20} className="text-admingreen mt-1" />
                  <p className="text-xl mx-4">{data?.name}</p>
                </div>
                <p className="border-b-2 border-border"></p>
              </div> */}
              {/*  */}
              <div className="py-10 cursor-not-allowed">
                <h1 className="text-xl text-subtext font-serif">
                  {langContent.phone_number}
                </h1>
                <div className="flex py-6">
                  <FaPhoneAlt size={20} className="text-admingreen mt-1" />
                  <p className="text-xl mx-4">{data?.phoneNumber}</p>
                </div>
                <p className="border-b-2 border-border"></p>
              </div>
              <div className="py-10 cursor-not-allowed">
                <h1 className="text-xl text-subtext font-serif">
                  {langContent.email}
                </h1>
                <div className="flex py-6">
                  <IoIosMail size={20} className="text-admingreen mt-1" />
                  <p className="text-xl mx-4">{data?.email}</p>
                </div>
                <p className="border-b-2 border-border"></p>
              </div>
              {/*  */}
              <div className="py-10 cursor-not-allowed">
                <h1 className="text-xl text-subtext font-serif">
                  {langContent.Customer_support_number}
                </h1>
                <div className="flex py-6">
                  <FaPhoneAlt size={20} className="text-admingreen mt-1" />
                  <p className="text-xl mx-4">{data?.customerSupportNumber}</p>
                </div>
                <p className="border-b-2 border-border"></p>
              </div>
              {/*  */}
              <div className="py-10 cursor-not-allowed">
                <h1 className="text-xl text-subtext font-serif">
                  {langContent.passwored}
                </h1>
                <div className="flex py-6">
                  <FaLock size={20} className="text-admingreen mt-1" />
                  <p className="text-xl mx-4">***********</p>
                </div>
                <p className="border-b-2 border-border"></p>
              </div>
            </div>
            <div className="flex justify-end my-4">
              <button
                onClick={handelStep}
                className="bg-admingreen shadow shadow-admingreen/90 rounded-full text-white flex gap-2 px-6 py-2"
              >
                <p>{langContent.Edit_account}</p>
                <BiEditAlt className="" size={20} />
              </button>
            </div>
          </div>
        )}
      </Segment>
      {step === 2 && <Editprofile />}
    </div>
  );
};

export default ViewProfile;
