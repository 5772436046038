import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import routes from "../../../routes";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import { Dropdown, Icon } from "semantic-ui-react";
import { FaUserCog } from "react-icons/fa";
import Sidebarmob from "./Side-bar-mob";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose, MdNotificationsActive } from "react-icons/md";
import MainLogo from "../../../../src/assets/White-naqla-logo.svg";
import { TiUserOutline } from "react-icons/ti";

const SideBar = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname } = useLocation();

  const [sid, SetSid] = React.useState(false);
  const HandleSid = () => {
    SetSid(!sid);
  };
  return (
    <div>
      <div className=" lg:flex hidden   ">
        <div className=" flex flex-col justify-between h-screen z-50 bg-maincolor w-64 text-white fixed    ">
          <div className="  text-2xl  ">
            <div className=" pt-10 pb-2 px-2 border-b-2 border-selectedcolor/30 flex justify-start  ">
              <img className=" w-32 mx-2 " src={MainLogo} alt="MainLogo" />
            </div>
            <ul className=" ">
              <div
                className={`    ${
                  pathname.startsWith(routes.admin.providerTabs)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white  "
                    : ""
                }  mt-16`}
              >
                <Link
                  to={routes.admin.providerTabs}
                  className=" flex py-4  text-xl font-bold "
                >
                  <FaUserCog className=" mx-4 text-2xl " />
                  <p>{langContent.provider}</p>
                </Link>
              </div>

              <div
                className={`    ${
                  pathname.startsWith(routes.admin.Notification)
                    ? "bg-selectedcolor/30 text-white ltr:border-l-2 rtl:border-r-2 border-white  "
                    : ""
                }  mt-6`}
              >
                <Link
                  to={routes.admin.Notification}
                  className=" flex py-4  text-xl font-bold "
                >
                  <MdNotificationsActive className=" mx-4 text-2xl " />
                  <p>{langContent.Notification}</p>
                </Link>
              </div>
            </ul>
          </div>
          {/*  under side bare lang*/}
          <div className="px-4">
            <div className="border-b-2 border-selectedcolor/30 ">
              <Dropdown
                trigger={
                  <div className=" flex  px-2 text-xl  ">
                    <Icon name="world" />
                    <span className="capitalize  px-1 ">
                      {lang === "en" ? "Language" : "اللغة"}
                    </span>
                  </div>
                }
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => {
                      setLang("en");
                      window.location.reload();
                    }}
                  >
                    {lang === "en" ? "English" : "الانجليزية"}
                  </Dropdown.Item>
                  <hr />
                  <Dropdown.Item
                    onClick={() => {
                      setLang("ar");
                      window.location.reload();
                    }}
                  >
                    {lang === "en" ? "Arabic" : "عربية"}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div
              className={`    ${
                pathname.startsWith(routes.admin.profile)
                  ? "text-[#B18200]"
                  : ""
              } mt-4`}
            >
              <Link to={routes.admin.profile}>
                <div className=" py-2 flex  cursor-pointer ">
                  <TiUserOutline size={25} />
                  <p className="mt-1 mx-2  ">{langContent.profile}</p>
                </div>
              </Link>
            </div>
            <div>
              <Link to={routes.auth.Login}>
                <button
                  onClick={() => {
                    "";
                  }}
                  className=" py-2 flex  cursor-pointer "
                >
                  <BiLogOut size={25} />
                  <p className="mt-1 mx-2  mb-10">{langContent.log_out}</p>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className=" w-full">
        <div
          className="  flex lg:hidden pt-2 bg-maincolor w-fit m-4 py-2 px-2 rounded-lg  cursor-pointer"
          onClick={HandleSid}
        >
          {sid ? (
            <MdClose size={25} className="text-white " />
          ) : (
            <GiHamburgerMenu size={25} className="text-white " />
          )}
        </div>
        <ul
          className={
            sid
              ? "fixed z-[100] ltr:left-0 top-0 rtl:right-0 w-full h-full ease-in-out duration-500"
              : "ease-in-out duration-500 fixed ltr:left-[-100%] rtl:right-[-100%]  "
          }
        >
          <div className="flex justify-between">
            <Sidebarmob
              handelSid={() => {
                HandleSid();
              }}
            />
            <button
              onClick={HandleSid}
              className="bg-white/90 w-full h-screen"
            ></button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
