import React from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { Button, Header, Image, Modal } from "semantic-ui-react";
import api from "../../api";
import { authAxios } from "../../config/axios-config";
import { useLanguage } from "../../lib/Language-Context";
import useAxios from "../../lib/use-axios";
import { content } from "../../localization/content";

function RejectProvidersModal({ providerId, onReload }) {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const [open, setOpen] = React.useState(false);

  const { run, isLoading, data, isError, error } = useAxios({});

  const handelReject = () => {
    const body = {
      providerId: providerId,
    };
    run(authAxios.post(api.admin.reject, body)).then(() => {
      onReload();
      setOpen(false);
    });
  };

  return (
    <Modal
      className="w-[30rem] h-[20rem] bg-white rounded-3xl"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      closeIcon
      open={open}
      trigger={
        <button className="text-red-600 text-4xl ">
          <RiCloseCircleFill />
        </button>
      }
    >
      {/* edit heeeeeeeeeeeeeeeeeeeeeeeeer */}
      <div>
        <h1 className="text-center text-maintext text-4xl py-8 font-serif">
          {langContent.Are_you_sure_you_want_to}
          <br></br> {langContent.reject_this_request}
        </h1>
        <div className="flex justify-center py-16">
          <Button
            onClick={() => {
              handelReject();
            }}
            className="bg-maincolor text-white text-2xl  py-2 w-80 h-14 rounded-full font-serif"
          >
            {langContent.Reject}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default RejectProvidersModal;
