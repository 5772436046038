const api = {
  auth: {
    login: "/auth/admin/login",
    RefrshToken: "/auth/refresh-token",
    sendforgetpassword: "/admin/send-forget-password",
  },
  criticalinfo: {
    forgetPassword: "/admin/send-forget-password",
  },
  admin: {
    activeproviders: "/admin/active-providers",
    pendingproviders: "/admin/pending-providers",
    //
    approve: "/admin/approve-provider",
    reject: "/admin/reject-provider",
    //
    providerDetails: (providerID = ":providerID") =>
      `/admin/provider-details/${providerID}`,
    //
    todayorders: (providerID = ":providerID") =>
      `/admin/orders/todays/${providerID}`,
    activeorders: (providerID = ":providerID") =>
      `/admin/orders/active/${providerID}`,
    pendingorders: (providerID = ":providerID") =>
      `/admin/orders/pending/${providerID}`,
    historyorders: (providerID = ":providerID") =>
      `/admin/orders/history/${providerID}`,
    pickupsorders: (providerID = ":providerID") =>
      `/admin/orders/pickups/${providerID}`,
    //
    orderdetails: (orderID = ":orderID") =>
      `/admin/orders/order-details/${orderID}`,
    //
    Notification: "/admin/notifications",
    //
    profile: "/admin/info",
  },
};

export default api;
