import React, { useState } from "react";
import { Segment, Table } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThreeSearchInput from "../../../src/component/Providers-Components/Three-Search-Input.js";
import Avatar from "../common/Avatar/Avater";
import routes from "../../routes";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import useFilter from "../../lib/use-filter";
import Paginationtable from "../common/Pagination/Pagination";

const ActiveProviderTable = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();

  const [data, setData] = useState();

  const { search, pathname } = useLocation();

  const { run, isLoading, isError, error } = useAxios({});

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const key = queryParams.get("key");
    const value = queryParams.get("value");
    const page = queryParams.get("page");
    const perPage = queryParams.get("perPage");

    if (key && page)
      run(
        authAxios
          .get(
            `${api.admin.activeproviders}?key=${key}&value=${
              value ? value : ""
            }&page=${page}&perPage=${perPage}`
          )
          .then(({ data }) => setData(data))
      );
  }, [run, search]);

  return (
    <div>
      <div className="flex justify-end ">
        <ThreeSearchInput />
      </div>
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        loading={isLoading}
      >
        <ToastContainer />
        <Table className="" basic="very">
          <Table.Header className="hidden md:contents">
            <Table.Row>
              <Table.HeaderCell className=" text-start px-4 border-none text-maintext font-normal text-xl  ">
                {langContent.name}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center border-none text-maintext  font-normal text-xl">
                {langContent.email}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center border-none text-maintext  font-normal text-xl">
                {langContent.phone_nember}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {data?.data?.map((e) => (
            <Table.Body>
              <div className="my-4 w-full"></div>
              <Table.Row className="shadow rounded-xl border-none">
                <Table.Cell className="text-center  border-none  w-[15rem]">
                  <div className="flex md:contents justify-between mx-2 ">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center   ">
                      {langContent.name}
                    </h1>
                    <div className=" flex justify-between mx-4 ">
                      {e?.image ? (
                        <div>
                          <img
                            className="w-12 h-12 rounded-full object-fill mt-1"
                            src={e?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div>
                          <Avatar
                            styles={
                              "w-12 h-12 rounded-full text-2xl text-white py-2 mt-1"
                            }
                            name={e?.name}
                          />
                        </div>
                      )}
                      <p className=" text-adminborder pt-4 font-normal text-xl">
                        {e?.name}
                      </p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8  border-none ">
                  <div className="flex md:contents justify-between mx-2  ">
                    <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.email}
                    </h1>
                    <p className=" pt-2 text-adminborder font-normal text-xl">
                      {e?.email}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-none  ">
                  <div className="flex md:contents justify-between mx-2  ">
                    <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.phone_nember}
                    </h1>
                    <p className="pt-2 text-adminborder font-normal text-xl">
                      {e?.mainPhoneNumber}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-none  ">
                  <button
                    onClick={() => {
                      history.push(routes.admin.providerdetails(e?.id));
                    }}
                    className="pt-2 text-admingreen font-normal text-xl"
                  >
                    {langContent.view_details}
                  </button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          ))}
          <div className="my-4 w-full"></div>
        </Table>
        <div className="flex justify-end edit_for_Pagination">
          <Paginationtable totalPages={data?.pagination?.totalPages} />
        </div>
      </Segment>
    </div>
  );
};
export default ActiveProviderTable;
