import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaUserAlt } from "react-icons/fa";
// import "./Input-form.css";

const InputForm = ({
  type,
  placeholder,
  label,
  width,
  value,
  icon,
  className,
  login,
  ...props
}) => {
  return (
    <div className="mx-2">
      {login === true ? (
        <div className="  flex flex-col-reverse  ">
          <div className=" flex justify-between md:w-[40rem] w-full  rounded-xl border-2  bg-blue-100/70   outline-none">
            <input
              className={`outline-none px-2 md:py-8 py-4 md:text-2xl text-lg  rounded-xl  bg-transparent w-full`}
              type={type}
              placeholder={placeholder}
              value={value}
              {...props}
            />
            <div className="flex mx-8 items-center text-maintext/80">
              {icon}
            </div>
          </div>
          <label className="my-4 text-xl transition-all ease-out">
            {label}
          </label>
        </div>
      ) : (
        <div className="flex flex-col-reverse ">
          <div
            className={`${className} flex justify-between rounded-xl border-2 outline-none `}
          >
            <input
              className={`outline-none px-2 py-2  rounded-xl  bg-transparent w-full`}
              type={type}
              placeholder={placeholder}
              value={value}
              {...props}
            />
            <div className="flex mx-8 items-center text-maintext/80">
              {icon}
            </div>
          </div>
          <label className="my-4  transition-all ease-out">{label}</label>
        </div>
      )}
    </div>
  );
};

export default InputForm;
