import React, { useState } from "react";
import { Tab } from "semantic-ui-react";
import { useLocation } from "react-router-dom";
import routes from "../../../routes";
import useTab from "../../../hooks/use-tab";
import { useLanguage } from "../../../lib/Language-Context";
import { content } from "../../../localization/content";
import ActiveProviderTable from "../../../component/Providers-Components/Active-Provider-Table";
import PendingProvidersTable from "../../../component/Providers-Components/Pending-Providers-Table";

const ProvidersTabs = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const { pathname } = useLocation();

  const panes = [
    {
      menuItem: `${langContent.active_providers}`,
      route: routes.admin.activeprovider,
      render: () => (
        <div>
          <Tab.Pane className="border-none w-full h-full my-2 p-0 rounded-2xl  ">
            <ActiveProviderTable />
          </Tab.Pane>
        </div>
      ),
    },
    {
      menuItem: `${langContent.pending_providers}`,
      route: routes.admin.pendingprovider,
      render: () => (
        <div>
          <Tab.Pane className="border-none w-full h-full my-2 p-0 rounded-2xl ">
            <PendingProvidersTable />
          </Tab.Pane>
        </div>
      ),
    },
  ];
  const { activeIndex, onTabChange } = useTab({ panes });

  return (
    <div className="">
      <div className="mx-auto h-auto rounded-3xl max-w-7xl  px-4 mt-14 edit-For-provider-tab   ">
        <Tab
          menu={{
            secondary: true,
            pointing: true,
            className: "flex flex-wrap  ",
          }}
          activeIndex={activeIndex}
          onTabChange={onTabChange}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default ProvidersTabs;
