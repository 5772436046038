import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import SideBar from "../component/common/Side-bare/Side-Bar";
import OrderDetails from "../component/Orders-Components/Order-Details";
import Notification from "../pages/app/Notification/Notification";
import OrderTabs from "../pages/app/orders/orders-tabs";
import ViewProfile from "../pages/app/Profile/View-profile";
import ProviderDetails from "../pages/app/provider/Provider-Details";
import ProvidersTabs from "../pages/app/provider/Providers-Tabs";
import routes from "../routes";
const AppLayouts = () => {
  return (
    <div className="">
      <div className=" ">
        <SideBar />
      </div>
      <div className="mx-auto lg:rtl:mr-[16rem]   lg:ltr:ml-[16rem] ">
        <Switch>
          <Route
            path={routes.admin.DetailsTodayOrders()}
            component={() => (
              <OrderDetails backpath={routes.admin.todayorders} />
            )}
          />
          <Route
            path={routes.admin.DetailsActiveOrders()}
            component={() => (
              <OrderDetails backpath={routes.admin.activeorders} />
            )}
          />
          <Route
            path={routes.admin.DetailsPendingOrders()}
            component={() => (
              <OrderDetails backpath={routes.admin.pendingorders} />
            )}
          />
          <Route
            path={routes.admin.DetailsHistoryOrders()}
            component={() => (
              <OrderDetails backpath={routes.admin.historyorders} />
            )}
          />
          <Route
            path={routes.admin.DetailsPickupOrders()}
            component={() => (
              <OrderDetails backpath={routes.admin.pickuporders} />
            )}
          />
          <Route
            exact
            path={routes.admin.providerdetails()}
            component={ProviderDetails}
          />
          <Route path={routes.admin.ordertabs()} component={OrderTabs} />
          <Route path={routes.admin.providerTabs} component={ProvidersTabs} />

          <Route path={routes.admin.Notification} component={Notification} />
          <Route path={routes.admin.profile} component={ViewProfile} />
        </Switch>
      </div>
    </div>
  );
};

export default AppLayouts;
