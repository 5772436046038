import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import loginpic from "../../../src/assets/pic-login.svg";
import logoadmin from "../../../src/assets/logo-admin-naqla.svg";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import routes from "../../routes";
import { Button, Image } from "semantic-ui-react";
import FormikInput from "../../component/common/formik/formik-input";
import { FaUserAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import useAxios from "../../lib/use-axios";
import axios from "axios";
import api from "../../api";
import auth from "../../utils/auth";

const LogIn = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const registerSchema = Yup.object({
    userName: Yup.string()
      .min(3, `${langContent.must_be_more_than_3_name} `)
      .max(20, `${langContent.max_20_name}`)
      .required(`${langContent.required}`),
    password: Yup.string()
      .min(8, `${langContent.must_be_more_than_7_pass}`)
      .max(20, `${langContent.max_20_pass}`)
      .required(`${langContent.required}`),
  });
  const { run, isLoading, isError, error, data, status } = useAxios();

  const Login = (values) => {
    const body = {
      name: values.userName,
      password: values.password,
    };
    run(axios.post(api.auth.login, body))
      .then((res) => {
        const { access_token, refresh_token } = res.data.data;
        auth.setToken({
          newAccessToken: access_token,
          newRefreshToken: refresh_token,
        });
        history.push(routes.admin.activeprovider);
      })
      .catch((err) => {});
  };

  return (
    <div className="">
      <div className="relative">
        {/* section for background */}
        <div className="flex  h-screen">
          <div className="flex-1 ">
            <img className="md:m-14 m-2 " src={logoadmin} alt="" />
          </div>
          <div className="flex-1  login-right-img "></div>
        </div>
        {/* section for login  */}
        <div className="bg-white absolute z-40 rounded-3xl p-4 shadow-maincolor w-full md:w-[50rem]  shadow-md center-abs  ">
          <div>
            <div className="flex flex-col mt-6">
              <h1 className="text-center text-maintext md:text-2xl text-xl font-serif">
                {langContent.Log_in}
              </h1>
              <h1 className="text-center text-admingreen md:text-2xl text-xl font-serif">
                {langContent.welcome}
              </h1>
            </div>
            <div className="flex justify-center mx-4">
              <Formik
                initialValues={{
                  userName: "",
                  password: "",
                }}
                onSubmit={Login}
                validationSchema={registerSchema}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <FormikInput
                      login={true}
                      label={langContent.User_Name}
                      placeholder={langContent.User_Name}
                      name="userName"
                      icon={<FaUserAlt className="md:text-2xl text-xl " />}
                    />
                    <FormikInput
                      login={true}
                      label={langContent.Password}
                      placeholder={langContent.Password}
                      name="password"
                      type={passwordType}
                      icon={
                        <div
                          className="cursor-pointer "
                          onClick={togglePassword}
                        >
                          {passwordType === "password" ? (
                            <FaEye className="md:text-2xl text-xl" />
                          ) : (
                            <FaEyeSlash className="md:text-2xl text-xl" />
                          )}
                        </div>
                      }
                    />
                    <p
                      onClick={() => {
                        history.push(routes.auth.ForgetPass);
                      }}
                      className="text-adminred flex  md:text-xl text-lg py-1 cursor-pointer"
                    >
                      {langContent.Forget_Password}
                    </p>
                    <span className="h-4 mt-1 text-red-600 text-left text-sm flex  ">
                      {lang === "en" ? error?.message?.en : error?.message?.ar}
                    </span>
                    <div className="flex justify-center">
                      <Button
                        loading={isLoading}
                        className=" md:w-[25rem] w-[20rem] md:py-6 py-4 rounded-full text-white bg-maincolor font-serif "
                      >
                        {langContent.Log_in}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
