import React, { useState } from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThreeSearchInput from "./Three-Search-Input.js";
import Avatar from "../common/Avatar/Avater";
import { RiCloseCircleFill } from "react-icons/ri";
import RejectProvidersModal from "./Reject-Providers-Modal";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import Paginationtable from "../common/Pagination/Pagination";
import AccpetProvidersModel from "./Accpet-Providers-Model";

const PendingProvidersTable = () => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  const [data, setData] = useState();
  const { search, pathname } = useLocation();

  const [forceReload, setForceReload] = useState(false);
  const onReload = React.useCallback(() => setForceReload((p) => !p), []);

  const [openAcceptModal, setOpenAcceptModal] = React.useState(false);

  const [isLoadingpost, setIsLoadingpost] = useState("");

  const { run, isLoading, isError, error } = useAxios({});
  const { run: runpost, error: errorpost } = useAxios({});

  const handelaccpet = (id) => {
    setIsLoadingpost(id);
    const body = {
      providerId: id,
    };
    runpost(authAxios.post(api.admin.approve, body))
      .then((res) => {
        onReload();
      })
      .finally(() => {
        setIsLoadingpost("");
      });
  };

  React.useEffect(() => {
    if (errorpost?.message) {
      setOpenAcceptModal(true);
    }
  }, [errorpost?.message]);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const key = queryParams.get("key");
    const value = queryParams.get("value");
    const page = queryParams.get("page");
    const perPage = queryParams.get("perPage");

    if (key && page)
      run(
        authAxios
          .get(
            `${api.admin.pendingproviders}?key=${key}&value=${
              value ? value : ""
            }&page=${page}&perPage=${perPage}`
          )
          .then(({ data }) => setData(data))
      );
  }, [run, search, forceReload]);

  return (
    <div>
      <div className="flex justify-end ">
        <ThreeSearchInput />
      </div>
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        loading={isLoading}
      >
        <ToastContainer />
        <Table className="" basic="very">
          <Table.Header className="hidden md:contents">
            <Table.Row>
              <Table.HeaderCell className=" text-start px-4 border-none text-maintext font-normal text-xl  ">
                {langContent.name}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center  border-none text-maintext font-normal text-xl  ">
                {langContent.email}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center   border-none text-maintext font-normal text-xl  ">
                {langContent.phone_nember}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center  border-none text-maintext font-normal text-xl  ">
                {langContent.commercail}
              </Table.HeaderCell>
              <Table.HeaderCell className=" text-center   border-none text-maintext font-normal text-xl  ">
                {langContent.license}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {data?.data?.map((e) => (
            <Table.Body key={e?.id}>
              <div className="my-4 w-full"></div>
              <Table.Row className="shadow rounded-xl border-noneedit-provider-active-table">
                <Table.Cell className="text-center py-8 border-none ">
                  <div className="flex md:contents justify-between mx-2 ">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center   ">
                      {langContent.name}
                    </h1>
                    <div className=" flex justify-between mx-4 ">
                      {e?.image ? (
                        <div>
                          <img
                            className="w-12 h-12 rounded-full object-fill mt-1"
                            src={e?.image}
                            alt=""
                          />
                        </div>
                      ) : (
                        <div>
                          <Avatar
                            styles={
                              "w-12 h-12 rounded-full text-2xl text-white py-2 mt-1"
                            }
                            name={e?.name}
                          />
                        </div>
                      )}
                      <p className=" text-adminborder pt-3 font-normal text-xl ">
                        {e?.name}
                      </p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8  border-none ">
                  <div className="flex md:contents justify-between mx-2  ">
                    <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.email}
                    </h1>
                    <p className=" text-adminborder font-normal text-xl">
                      {e?.email}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-none  ">
                  <div className="flex md:contents justify-between mx-2  ">
                    <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.phone_nember}
                    </h1>
                    <p className=" text-adminborder font-normal text-xl">
                      {e?.mainPhoneNumber}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8  border-none ">
                  <div className="flex md:contents justify-between mx-2  ">
                    <h1 className="text-hedertext bg-gray-50  w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.commercail}
                    </h1>
                    <p className=" text-adminborder font-normal text-xl">
                      {e?.commericalNumber}
                    </p>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-none">
                  <div className="flex md:contents justify-between mx-2 ">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.license}
                    </h1>
                    <div className="flex justify-center gap-3  ">
                      <p className=" text-adminborder font-normal text-xl">
                        {e?.licenceNumber}
                      </p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center py-8 border-none">
                  <div className="flex md:contents justify-between mx-2 ">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4  rounded-lg md:hidden flex justify-center">
                      {langContent.action}
                    </h1>
                    <div className="flex justify-center gap-5 mx-2 ">
                      <Button
                        loading={isLoadingpost === e?.id}
                        onClick={() => {
                          handelaccpet(e?.id);
                        }}
                        basic
                        color="green"
                        className="rounded-full   "
                      >
                        {langContent.accept}
                      </Button>
                      <button className=" text-adminborder font-normal text-xl  pt-1">
                        <RejectProvidersModal
                          providerId={e?.id}
                          onReload={onReload}
                        />
                      </button>
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
              <div className="my-4 w-full"></div>
            </Table.Body>
          ))}
        </Table>
        <div className="flex justify-end edit_for_Pagination">
          <Paginationtable totalPages={data?.pagination?.totalPages} />
        </div>
        {openAcceptModal && (
          <AccpetProvidersModel
            open={openAcceptModal}
            onClose={() => setOpenAcceptModal(false)}
            massg={
              lang === "en" ? errorpost?.message.en : errorpost?.message.ar
            }
          />
        )}
      </Segment>
    </div>
  );
};
export default PendingProvidersTable;
