import React from "react";
import { MdError } from "react-icons/md";

const ErrorMessage = ({ error }) => {
  return (
    <span className=" h-4 mt-1 text-red-600 text-left text-sm flex  ">
      {" "}
      <MdError size={15} className="mt-0.5 mx-1" />
      {error}
    </span>
  );
};

export default ErrorMessage;
