const basePath = process.env.REACT_APP_BASE_PATH || "";

const routes = {
  auth: {
    baseAuth: `/admin/auth`,
    Login: `/admin/auth/Log-in`,
    ForgetPass: `/admin/auth/forget-password`,
    CreateNewPass: `/admin/auth/forget-passwor/create-new-password`,
  },
  ediPersonalInfo: {
    default: `/critical-info-update`,
    changePassword: `/critical-info-update/change-password`,
  },
  admin: {
    baseAdmin: `/admin/dashboard`,
    //providers tabs
    providerTabs: `/admin/dashboard/provider`,
    activeprovider: `/admin/dashboard/provider/active`,
    providerdetails: (providerId = ":providerId") =>
      `/admin/dashboard/provider/provider-details/${providerId}`,
    pendingprovider: `/admin/dashboard/provider/pending`,
    // order tabs in providers tabs
    ordertabs: (providerId = ":providerId") =>
      `/admin/dashboard/provider/provider-details/orders/${providerId}`,
    todayorders: `/admin/dashboard/provider/provider-details/orders/today`,
    activeorders: `/admin/dashboard/provider/provider-details/orders/active`,
    pendingorders: `/admin/dashboard/provider/provider-details/orders/pending`,
    historyorders: `/admin/dashboard/provider/provider-details/orders/history`,
    pickuporders: `/admin/dashboard/provider/provider-details/orders/pick-up`,
    DetailsTodayOrders: (orderId = ":orderId") =>
      `/admin/dashboard/provider/provider-details/orders/today/details/${orderId}`,
    DetailsActiveOrders: (orderId = ":orderId") =>
      `/admin/dashboard/provider/provider-details/orders/active/details/${orderId}`,
    DetailsPendingOrders: (orderId = ":orderId") =>
      `/admin/dashboard/provider/provider-details/orders/pending/details/${orderId}`,
    DetailsHistoryOrders: (orderId = ":orderId") =>
      `/admin/dashboard/provider/provider-details/orders/history/details/${orderId}`,
    DetailsPickupOrders: (orderId = ":orderId") =>
      `/admin/dashboard/provider/provider-details/orders/pick-up/details/${orderId}`,
    //Notification
    Notification: `/admin/dashboard/notification`,
    //profile
    profile: `/admin/dashboard/profile`,
  },
};

export default routes;
