import React from "react";
import { Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Sidebareedit from "../component/common/edit-personal-info-components/side-bare/side-bare-edit";
import Changepassword from "../pages/edit-personal-info/change-password";
import routes from "../routes";

const Editpersonalinfolayouts = () => {
  return (
    <div className="">
      <Sidebareedit />
      <div className="mx-auto lg:rtl:mr-[19rem] lg:ltr:ml-[19rem]">
        <Switch>
          <Route
            path={routes.ediPersonalInfo.changePassword}
            component={Changepassword}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Editpersonalinfolayouts;
