import React from "react";
import { Segment, Table } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import { useLanguage } from "../../lib/Language-Context";
import { content } from "../../localization/content";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ThreeSearchInput from "../../../src/component/Providers-Components/Three-Search-Input.js";
import Avatar from "../common/Avatar/Avater";
import routes from "../../routes";
import Status from "../common/status/status";
import { BsBoxSeam } from "react-icons/bs";

const BranshTableProviderDetails = ({ branchdate }) => {
  const [lang, setLang] = useLanguage("");
  const langContent = content[lang];
  const history = useHistory();
  return (
    <div className="mx-auto">
      <Segment
        className="border-none shadow-none p-0 m-0 -z-1"
        // loading={isLoading}
      >
        <ToastContainer />
        <Table className="" basic="very">
          {branchdate?.map((e) => (
            <Table.Body>
              <div className="my-4 "></div>
              <Table.Row className="">
                <Table.Cell className="text-center  border-none text-xl text-maintext ">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Id}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p>{e?.id}</p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center  border-none text-xl text-maintext  ">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {lang === "en" ? "Branch name" : "أسم الفرع"}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p>{e?.name}</p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center  border-none text-xl text-maintext  ">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Address}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p>{e?.area?.name}</p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center  border-none text-xl text-maintext  ">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Address}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p className="bg-maincolor text-white rounded-full py-2 px-4">
                        {e?.isArchived === false ? (
                          <p>{lang === "en" ? "Active" : " النشطة"}</p>
                        ) : (
                          <p>{lang === "en" ? "Archived" : " مؤرشفة"}</p>
                        )}
                      </p>
                    </div>
                  </div>
                </Table.Cell>
                <Table.Cell className="text-center  border-none text-xl text-maintext  ">
                  <div className="flex justify-between  md:block">
                    <h1 className="text-hedertext bg-gray-50 w-32 p-4 rounded-lg md:hidden flex justify-center  ">
                      {langContent.Quantity}
                    </h1>
                    <div className="flex justify-center gap-4 mx-2 py-4 md:py-0">
                      <p> {e?.containers}</p>
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
              <div className="my-4 "></div>
            </Table.Body>
          ))}
        </Table>
      </Segment>
    </div>
  );
};
export default BranshTableProviderDetails;
