import React from "react";

const Avatar = ({ name, styles }) => {
  const stringToColor = function (str) {
    let hash = 0;
    for (let i = 0; i < str?.length; i++)
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xfff;
      colour += value.toString(16).substring(0, 2);
    }
    return colour;
  };

  const backgroundColor = stringToColor(name?.substring(0, 4));
  return (
    <div
      style={{
        "background-color": backgroundColor,
      }}
      className={` ${styles}`}
    >
      {name?.substring(0, 1).toUpperCase()}
    </div>
  );
};

export default Avatar;
